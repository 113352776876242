<template>
  <div class="sub-table">
    <b-table-simple>
      <b-thead class="table-header">
        <b-tr class="text">
          <b-th>Código de barras</b-th>
          <b-th>Lote</b-th>
          <b-th>Nome</b-th>
          <b-th>Qtde.</b-th>
          <b-th>Custo unitário</b-th>
          <b-th>Venda unitário</b-th>
          <b-th>ICMS unitário</b-th>
          <b-th>Total produtos</b-th>
        </b-tr>
      </b-thead>
      <b-tbody class="table-row">
        <b-tr v-for="(transaction, index) in transactions" :key="transaction.id" class="text">
          <b-td class="td-text">{{ transaction.barcode  ?? '-'}}</b-td>
          <b-td class="td-text">
            <span :id="`batch_tooltip_${transaction.id}_${index}`">
            {{ transaction.batch }}
            </span>
            <BatchDetail
              :target="`batch_tooltip_${transaction.id}_${index}`"
              :transaction="transaction"
            />
          </b-td>
          <b-td class="td-text">{{ transaction.product.name }}</b-td>
          <b-td class="td-text">{{ transaction.quantity }}</b-td>
          <b-td class="td-text">{{ parseNumberToMoney(transaction.purchase_price) }}</b-td>
          <b-td class="td-text">{{ parseNumberToMoney(transaction.sale_price) }}</b-td>
          <b-td class="td-text">{{ parseNumberToMoney(transaction.icms_value) }}</b-td>
          <b-td class="td-text">{{ parseNumberToMoney(totalValue(transaction)) }}</b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot class="table-footer text">
        <b-tr>
          <b-td colspan="8" class="text-right">
            <p class="mr-4 total-value">Valor total
              {{ parseNumberToMoney(transactions.reduce((total, transaction) => total + totalValue(transaction), 0))
              }}
            </p>
          </b-td>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
  </div>
</template>
<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'

export default {
  name: 'EntryTransactionsTable',
  components: {
    BatchDetail: () => import('@/components/Warehouse/BatchDetail'),
  },
  props: {
    transactions: {
      type: Array,
      required: true
    }
  },
  methods: {
    parseNumberToMoney,
    totalValue(transaction) {
      return transaction.quantity * transaction.purchase_price
    }
  }
}
</script>
<style lang="scss" scoped>
.sub-table {
  border: 1px solid var(--neutral-200);
  padding: 1rem;
  border-radius: 15px;
  margin: 1rem 0;

  .text {
    color: var(--type-active);
  }

  .total-value {
    font-weight: 700;
  }

  .table-header {
    border-bottom: 1px solid var(--neutral-100);
    background-color: var(--neutral-100);
  }

  td {
    border: 0 !important;
  }

  .table-footer {
    background-color: var(--neutral-100);
    border: 0;
  }

  .table-row {
    border-bottom: 1px dashed var(--neutral-300) !important;
  }
}
</style>